import { loadScript } from "./script";

function loadGoogleMapApi() {
    if (typeof window !== "undefined") {
        if (!document.querySelector("#google-maps")) {            
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${process.env.GMAPS_API_KEY}&region=US&libraries=places&v=3.53`,
                document.querySelector("head"),
                "google-maps"
            );
        }
    }
}

export { loadGoogleMapApi };