import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  NativeSelect,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Suggestion, geocodeByAddress } from "react-places-autocomplete";
import { useContext, useEffect, useRef, useState, createRef } from "react";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import AlertDialog from "./AlertDialog";
import BirthdatePicker from "../components/BirthdatePicker";
import ContentWithLinks from "./ContentWithLinks";
import { Context } from "../App";
import { FormFields } from "../types/StoreMap";
import FormValues from "../types/FormValues";
import LocationSearchInput from "./LocationSearchInput";
import ReactSignatureCanvas from "react-signature-canvas";
import axios from "axios";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { utilityGenderFormat } from "../utils/utilityGenderFormat";
import { isInLoyaltyBlacklist } from "../utils/loyaltyBlacklist";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Dropdown from "./Dropdown";
import parsePhoneNumber from "libphonenumber-js";
import * as EmailValidator from 'email-validator';

import phonePrefixes from "../assets/phonePrefixes.json";
import iconCheck from "../assets/checked.png";
import { validateEmail } from "../services/validateEmail";
import useDebounce from "../hooks/useDebounce";
import Select from "./Select";
import type { CountryCode } from "libphonenumber-js";

(window as any).recaptchaOptions = {
  useRecaptchaNet: true,
};

const CustomCheckboxLabel = styled(FormLabel)`
  font-size: 10px;
  letter-spacing: normal;
`;

const CustomSelectLabel = styled(FormLabel)`
  display: inline-block;
  margin-bottom: 5px;
`;

const SubmitButton = styled(Button)`
  margin: 10px 0;
`;

const Disclaimer = styled(Typography)`
  font-size: 10px;
  text-align: center;
`;

const GridRecaptcha = styled(Grid)`
  justify-content: center;
  display: flex;
  margin: 10px 0;
`;

interface PropsSelectDropdown {
  formOliver: boolean;
  formPersol: boolean;
  formDavidClulow: boolean;
}

const StyledDropdown = styled(Dropdown)`
  height: 40px;
  border: 1px solid rgba(189, 189, 189, 0.6);
`;

const SelectCountryDropdown = styled(CountryDropdown)<PropsSelectDropdown>`
  border: ${({ formOliver, formPersol, formDavidClulow }) =>
    formOliver
      ? `1px solid #202125`
      : formPersol
      ? `1px solid #dadada`
      : formDavidClulow
      ? `2px solid #66bbb0`
      : `1px solid rgba(189, 189, 189, 0.6)`};
  border-radius: ${({ formOliver , formPersol }) =>
    formOliver 
      ? `0px` 
      : formPersol
      ? `0px` 
      : `5px`};
  font-family: inherit;
  color: inherit;
  font-size: 12px;
  width: 100%;
  height: 40px;
  bottom: 5px;
  position: relative;
`;
const SelectRegionDropdown = styled(RegionDropdown)<PropsSelectDropdown>`
  border: ${({ formOliver, formPersol, formDavidClulow }) =>
    formOliver
      ? `1px solid #202125`
      : formPersol
      ? `1px solid #dadada`
      : formDavidClulow
      ? `2px solid #66bbb0`
      : `1px solid rgba(189, 189, 189, 0.6)`};
  border-radius: ${({ formOliver , formPersol }) =>
    formOliver 
      ? `0px` 
      : formPersol
      ? `0px` 
      : `5px`};
  font-family: inherit;
  color: inherit;
  font-size: 12px;
  width: 100%;
  height: 40px;
  bottom: 5px;
  position: relative;
`;

const DisclaimerRed = styled(CustomCheckboxLabel)`
  color: red;
  font-size: 11px;
`;

const StyledWrapper = styled(Grid)<{ direction?: string }>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
`;

const StyledPhonePrefixDropdown = styled(StyledDropdown)`
  width: 100%;
`;

export type FieldsConfig = {
  [key in FormFields]?: boolean;
};

export type Province = {
  label: string;
  value: string;
  region: string;
};

type TMessageEmail = "success" | "error" | "warning" | "";

interface FormProps {
  fieldsConfig: FieldsConfig;
  formInitialValues?: FormValues;
  resetPage: () => void;
  disabledFields: boolean;
  publicForm: string;
  posType: string;
  merakiRedirectUrl?: string;
  merakiUserContinueUrl?: string;
  pos: boolean;
}

const WiFiForm = ({
  fieldsConfig,
  formInitialValues,
  resetPage,
  disabledFields,
  publicForm,
  merakiRedirectUrl,
  merakiUserContinueUrl,
  posType,
  pos,
}: FormProps) => {
  const { t } = useTranslation();
  const firstRender = useRef(true);
  const { storeIsdcData, storeMapData, subscriptionKey, usingPos, partyId } =
    useContext(Context);
  const [alertDialogOpen, setAlertDialogOpen] = useState<boolean>(false);
  const [alertDialogContent, setAlertDialogContent] = useState<string>("");
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
  const [submitSuccess, setSubmitSuccess] = useState<boolean>(true);
  const [marketingEnabled, setMarketingEnabled] = useState<boolean | undefined>(
    undefined
  );
  const [errorMessageEmail, setErrorMessageEmail] = useState({
    status: "success",
    message: "",
  });
  const [fieldsDisabled, setFieldsDisabled] = useState<boolean>(false);
  const { isdc__loyalty_enabled } = storeIsdcData;
  const loyalty = isdc__loyalty_enabled && !posType && !pos;
  const loyaltyEMEA = loyalty && !(["US"].includes(String(storeIsdcData.country)))

  const [inProgress, setInProgress] = useState<boolean>(false);
  const sigCanvas = useRef() as React.MutableRefObject<any>;
  const [signatureError, setSignatureError] = useState<boolean>(false);
  const [province, setProvince] = useState<string>();
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [phonePrefixCountryCode, setPhoneCountryCode] = useState<string>();
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState<boolean>(false);
  const [suggestion, setSuggestion] = useState<string | Suggestion | null>(
    null
  );

  const isContinentalCountry =
    storeIsdcData.country === "TR" || storeIsdcData.country === "US";

  useEffect(() => {
    firstRender.current = false;
  }, []);

  useEffect(() => {
    setFieldsDisabled(disabledFields);
  }, [disabledFields]);

  const retrySubmit = () => {
    setAlertDialogOpen(false);
    formik.submitForm();
  };

  const handleRedirectSubmit = () => {
    if (storeIsdcData.country) {
      const countryCode =
        storeIsdcData.country === "CA" && storeIsdcData.isdc__language === "fr"
          ? "CA-FR"
          : storeIsdcData.country;
      if (publicForm){
        if (merakiRedirectUrl){
          // as requested by business team, we are ignoring the "continue URL" requested by the device
          // ...and always telling Meraki to send the user to the ecommerce home page instead
          window.location.replace(`${merakiRedirectUrl}?continue_url=${storeMapData.externalLinks.publicFormRedirect[countryCode]}`);
          /*
          if (merakiUserContinueUrl){
            window.location.replace(
              `${merakiRedirectUrl}?continue_url=${merakiUserContinueUrl}`
            );
          } else {
            window.location.replace(
              `${merakiRedirectUrl}?continue_url=${storeMapData.externalLinks.publicFormRedirect[countryCode]}`
            );
          }
          */
        } else {
          window.location.replace(
            `${storeMapData.externalLinks.publicFormRedirect[countryCode]}`
          );
        }
      } else setAlertDialogOpen(true);
    }
  };

  const submitPOSFailed = () => {
    backToPOS(false);
  };

  const backToPOS = (response: boolean) => {
    clearForm();
    resetPage();
    window.location.replace("xstorepos://?response=" + response);
  };

  const validateDate = (value: string) => {
    const inputDate = new Date(value);
    const now = new Date();
    const diff = now.getFullYear() - inputDate.getFullYear();
    return storeIsdcData.country === "US" ? diff < 16 : diff < 18;
  };

  const marketingAccepted = () =>
    formInitialValues?.email_marketing ||
    formInitialValues?.phone_marketing ||
    formInitialValues?.postal_marketing
      ? "true"
      : formInitialValues?.email_marketing === false &&
        formInitialValues?.phone_marketing === false &&
        formInitialValues?.postal_marketing === false
      ? "false"
      : "";

  const recaptchaRef = createRef<any>();

  const formik = useFormik({
    initialValues: {
      first_name: formInitialValues?.first_name ?? "",
      last_name: formInitialValues?.last_name ?? "",
      gender: formInitialValues?.gender
        ? utilityGenderFormat(formInitialValues?.gender.toLowerCase())
        : "",
      birthdate: formInitialValues?.birthdate
        ? formInitialValues?.birthdate.split("T")[0]
        : "",
      email: formInitialValues?.email ?? "",
      address: formInitialValues?.address ?? "",
      zip_code: formInitialValues?.zip_code ?? "",
      city: formInitialValues?.city ?? "",
      state: formInitialValues?.state ?? "",
      country: formInitialValues?.country ?? "",
      mobile: formInitialValues?.mobile ?? "",
      loyalty: formInitialValues?.loyalty ?? "",
      loyalty_accepted: formInitialValues?.loyalty_accepted ?? false,
      consent_data: formInitialValues?.consent_data ?? "",
      phone_marketing: formInitialValues?.phone_marketing ?? false,
      postal_marketing: formInitialValues?.postal_marketing ?? false,
      email_marketing: formInitialValues?.email_marketing ?? false,
      only_email_marketing: formInitialValues?.email_marketing ?? false,
      only_phone_marketing: formInitialValues?.phone_marketing ?? false,
      marketingAccepted: marketingAccepted(),
    },
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("source_site", storeIsdcData.store_number);
      formData.append(
        "form_source",
          merakiRedirectUrl
          ? "wifi_form"
          : publicForm
          ? "public_form"
          : usingPos || posType === "desktop"
          ? "pos_app"
          : "isdc_app"
      );
      formData.append("form_language", storeIsdcData.isdc__language ?? "EN");

      if (partyId) {
        formData.append("party_id", partyId);
      }
      
      if (publicForm){
        const recaptchaValue = recaptchaRef?.current.getValue()
        if(recaptchaValue) {
          formData.append("g_recaptcha_response", recaptchaValue);
        }
      }

      if (storeIsdcData.country === "US") {
        formData.append(FormFields.EmailMarketing, "true");
      }

      if (storeIsdcData.country === "US") {
        if (!!formik.values.address)
          formData.append(FormFields.PostalMarketing, "true");
        else formData.append(FormFields.PostalMarketing, "false");
      }

      if (values.country) {
        values.country = values.country.toUpperCase();
      }

      for (const [key, value] of Object.entries(values)) {
        if (fieldsConfig[key as keyof FieldsConfig]) {
          formData.append(key, value.toString());
        }
      }

      if (fieldsConfig.signature && !!!publicForm) {
        if (sigCanvas.current.isEmpty()) {
          setSignatureError(true);
          return;
        }

        const blob: Blob = await new Promise((resolve) =>
          sigCanvas.current.getTrimmedCanvas().toBlob(resolve)
        );
        formData.append("signature", blob, "signature.png");
      }

      if (
        fieldsConfig.consent_data &&
        fieldsConfig.only_email_marketing &&
        fieldsConfig.only_phone_marketing &&
        fieldsConfig.only_postal_marketing
      ) {
        formData.delete("consent_data");
        formData.delete("only_email_marketing");
        formData.delete("only_phone_marketing");
        formData.delete("only_postal_marketing");
        formData.append(
          "email_marketing",
          formik.values.email_marketing.toString()
        );
        formData.append(
          "phone_marketing",
          formik.values.phone_marketing.toString()
        );
        formData.append(
          "postal_marketing",
          formik.values.postal_marketing.toString()
        );
      }
      if (loyalty)
        formData.append(
          "subscribe_to_loyalty",
          formik.values.loyalty.toString()
        );
        
        if (loyaltyEMEA){
          formData.append(
            "loyalty_communication_consent",
            formik.values.loyalty_accepted.toString()
          );
        } else {
          formData.append(
            "loyalty_communication_consent",
            "true"
          );
        }

      setInProgress(true);
      let success = false;
      if (fieldsConfig[FormFields.ConsentData] && !formik.values.consent_data) {
        setSubmitSuccess(false);
        handleCloseAndClearForm();
        setInProgress(false);
      } else {
        axios
          .post(`${process.env.API_HOST}/cep-ms-isdcbff/v1/contact`, formData, {
            headers: {
              "Ocp-Apim-Subscription-Key": subscriptionKey,
            },
          })
          .then(function (response) {
            success = true;
            setAlertDialogContent(t("form.thankyou-message"));
            setSubmitSuccess(true);
          })
          .catch(function (error) {
            setAlertDialogContent(t("form.error-message"));
            setSubmitSuccess(false);
          })
          .finally(function () {
            setInProgress(false);

            if (usingPos && success) {
              //redirect to POS
              backToPOS(true);
            } else if (success) {
              handleRedirectSubmit();
            } else setAlertDialogOpen(true);
          });
      }
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors: {
        [key in FormFields]?: string;
      } & { marketingAccepted?: string } = {};

      if (validateDate(values.birthdate)) {
        errors.birthdate = t("form.birthdate-invalid");
      }

      const birthdate_mandatory = storeIsdcData.country === "AU" || storeIsdcData.country === "NZ" || storeIsdcData.country === "SG" ;
      if (birthdate_mandatory && (values.birthdate===null || values.birthdate==="")) {
        errors.birthdate = t("form.birthdate-invalid");
      }

      if (values.country && !/^[a-zA-Z]{2}$/.test(values.country)) {
        errors.country = t("form.country-invalid");
      }

      // Additional post code validation for AUNZ
      if (storeIsdcData.country === "SG" && values.zip_code && !/^[0-9]{6}$/.test(values.zip_code)) {
        errors.zip_code = t("Postcode must be 6 digits");
      }
      else if ( (storeIsdcData.country === "AU" || storeIsdcData.country === "NZ" ) && values.zip_code && !/^[0-9]{4}$/.test(values.zip_code)) {
        errors.zip_code = t("Postcode must be 4 digits");
      }

      if (!posType) {
        if (values.address) {
          if (!values.city) errors.city = t("form.fillfield.error");
          if (!formSEA && !values.state) errors.state = t("form.fillfield.error");
          if (!values.zip_code) errors.zip_code = t("form.fillfield.error");
          if (!values.country) errors.country = t("form.fillfield.error");
        }
      }

      return errors;
    },
  });

  const email = useDebounce<string>(formik.values.email, 500);
  const phoneNumber = useDebounce<string>(formik.values.mobile, 500);
  const invalidState = !formik.values.state && Boolean(formik.errors.state);
  const invalidCity = !formik.values.city && Boolean(formik.errors.city);
  const invalidCountry =
    !formik.values.country && Boolean(formik.errors.country);
  const invalidZipCode = Boolean(formik.errors.zip_code);

  const isSignatureUnavailable = !(
    fieldsConfig[FormFields.Signature] && !!!publicForm
  );

  const invalidAddressFields =
    invalidState || invalidCountry || invalidCity || invalidZipCode;

  useEffect(() => {
    (async () => {
      if (!posType) {
        if (email) {
          
          const isValidFormat = EmailValidator.validate(email);

          if (isValidFormat) {
            const { validation_outcome } = await validateEmail({
              subscriptionKey,
              email,
            });

            const formattedDomainEmail = email.split("@")[1];

            switch (validation_outcome) {
              case "error":
                return setErrorMessageEmail({
                  status: validation_outcome,
                  message: `${t("form.email-error")}: @${formattedDomainEmail}`,
                });

              case "warning":
                return setErrorMessageEmail({
                  status: validation_outcome,
                  message: `${t("form.email-warning")}${formattedDomainEmail}`,
                });

              default:
                return setErrorMessageEmail({ status: "success", message: "" });
            }
          } else {
            return setErrorMessageEmail({
              status: "error",
              message: `${t("form.email-invalid")}`,
            });
          }
        } else {
          setErrorMessageEmail({
            status: "success",
            message: "",
          });
        }
      }
    })();
  }, [email, posType, subscriptionKey, t]);

  const clearForm = () => {
    if (sigCanvas?.current) {
      sigCanvas.current.clear();
    }
    formik.resetForm();
    setProvince("--");
    setSuggestion(null);
  };

  const handleCloseAndClearForm = () => {
    clearForm();
    resetPage();
    setAlertDialogOpen(false);
  };

  // function that gets called instead of onChange function when user hits the Enter key or clicks on a suggestion item.
  const handleSelect = async (
    address: string,
    placeId?: string,
    suggestion?: Suggestion
  ) => {
    try {
      if (suggestion && suggestion.id === "add") {
        formik.setValues(
          (prevValues) => ({
            ...prevValues,
            // we use the name to tell Formik which key of `values` to update
            // [FormFields.ZipCode]: "",
            // [FormFields.City]: "",
            // [FormFields.Country]: "",
            // [FormFields.State]: "",
            [FormFields.Address]: suggestion.description,
          }),
          false
        );
        return;
      }
      const result = await geocodeByAddress(address);

      const regionOptions = Array.from(
        document.querySelectorAll('select[name="state"] option')
      ) as HTMLOptionElement[];

      const regions = regionOptions.map((item) => item.value.toLowerCase());

      const addresses = result[0].address_components;
      const postalCode = addresses.find((address) =>
        address.types.includes("postal_code")
      );
      const country = addresses.find((address) =>
        address.types.includes("country")
      );
      let state = addresses.find((address) =>
        address.types.includes("administrative_area_level_1")
      );

      if (!regions.includes(state?.short_name.toLowerCase() || ""))
        state = undefined;

      const geoProvince = addresses.find((address) =>
        address.types.includes("administrative_area_level_2")
      );

      if (
        !state &&
        regions.includes(geoProvince?.short_name.toLowerCase() || "")
      )
        state = geoProvince;

      const city = addresses.find(
        (address) =>
          address.types.includes("locality") ||
          address.types.includes("postal_town")
      );

      let province: Province | undefined = undefined;

      if (provinces.length > 0) {
        province = provinces.find(
          ({ value }) =>
            value.toLocaleLowerCase() ===
              geoProvince?.short_name.toLowerCase() ||
            value.toLocaleLowerCase() === state?.short_name.toLowerCase()
        );
        setProvince(province?.value);
      }

      formik.setValues(
        (prevValues) => ({
          ...prevValues,
          // we use the name to tell Formik which key of `values` to update
          [FormFields.ZipCode]: postalCode ? postalCode.long_name : "",
          [FormFields.City]: city ? city.long_name : "",
          [FormFields.Country]: country ? country.short_name : "",
          [FormFields.State]:
            state && province ? province.value : state?.short_name || "",
        }),
        false
      );

      formik.validateForm();

      Promise.resolve(result);
    } catch (error) {
      console.error("Error", error);
      Promise.reject(error);
    }
  };

  useEffect(() => {
    (async () => {
      const country = formik.values.country.toLowerCase();

      if (country === "") return;

      try {
        const provinces = await import(`../assets/provinces/${country}.json`);
        setProvinces(provinces?.default || []);
      } catch {
        setProvinces([]);
      }
      
    })();
  }, [formik.values.country]);

  useEffect(() => {
    const country = formik.values.country.toUpperCase();
    const phonePrefix = phonePrefixes.find((prefix) => prefix.code === country);

    phonePrefix
      ? setPhoneCountryCode(phonePrefix.code)
      : setPhoneCountryCode("");
  }, [formik.values.country]);

  useEffect(() => {
    if (!posType) {
      if (phoneNumber) {
        const parsedPhoneNumber = parsePhoneNumber(
          phoneNumber,
          phonePrefixCountryCode as CountryCode
        );

        setInvalidPhoneNumber(!parsedPhoneNumber?.isValid());
      } else {
        setInvalidPhoneNumber(false);
      }
    }
  }, [posType, phoneNumber, phonePrefixCountryCode]);

  useEffect(() => {
    if (formInitialValues?.state) {
      setProvince(formInitialValues?.state);
    }
  }, [formInitialValues?.state]);

  useEffect(() => {
    if (storeIsdcData.country && formik.values.country === "")
      formik.setFieldValue(FormFields.Country, storeIsdcData.country);
  }, [storeIsdcData.country, formik.values.country]);

  const handleProvinceChange = (provinceValue: string) => {
    formik.setFieldValue(FormFields.State, provinceValue);
    setProvince(provinceValue);
  };

  const handlePhonePrefixChange = (prefixCountryCode: string) => {
    setPhoneCountryCode(prefixCountryCode);
  };

  const handleConsentSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    formik.handleChange(event);
    event.target.options[0].disabled = true;
    const picked = event.target.value === "true";
    setMarketingEnabled(picked);
    formik.setFieldValue(FormFields.EmailMarketing, picked);
    // commented the following lines, so that only the email consent is flagged
    // automatically when "YES" is chosen from the dropdown
    //formik.setFieldValue(FormFields.PhoneMarketing, picked);
    //formik.setFieldValue(FormFields.PostalMarketing, picked);
  };

  const formOakley = storeMapData.themeName === "oakley";
  const formOliver = storeMapData.themeName === "oliver";
  const formPersol = storeMapData.themeName === "persol";
  const formDavidClulow = storeMapData.themeName === "davclu";
  const confirmLoyaltyCountry =
    storeIsdcData.country === "US" ? "US" : "default";

  const formAUNZ = storeIsdcData.country === "AU" || storeIsdcData.country === "NZ" ;
  const formSEA = storeIsdcData.country === "SG";
  const countryDropdownDisabled = fieldsDisabled || formAUNZ || formSEA;
  const stateDropdownDisabled = fieldsDisabled;
  const mobileCountryDropdownDisabled = fieldsDisabled || formAUNZ;

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {fieldsConfig[FormFields.FirstName] && (
            <Grid item xs={12}>
              <InputLabel required htmlFor="first_name">
                {t("form.first_name")}
              </InputLabel>
              <TextField
                disabled={fieldsDisabled}
                required
                fullWidth
                size="small"
                id={FormFields.FirstName}
                name={FormFields.FirstName}
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
                label={t("form.first_name")}
              />
            </Grid>
          )}
          {fieldsConfig[FormFields.LastName] && (
            <Grid item xs={12}>
              <InputLabel required htmlFor="last_name">
                {t("form.last_name")}
              </InputLabel>
              <TextField
                disabled={fieldsDisabled}
                required
                fullWidth
                size="small"
                id={FormFields.LastName}
                name={FormFields.LastName}
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
                label={t("form.last_name")}
              />
            </Grid>
          )}
          {fieldsConfig[FormFields.Email] && (
            <Grid item xs={12}>
              <InputLabel required htmlFor={FormFields.Email}>
                {t("form.email")}
              </InputLabel>
              <TextField
                disabled={fieldsDisabled}
                required
                fullWidth
                type="email"
                size="small"
                id={FormFields.Email}
                name={FormFields.Email}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.email)}
                helperText={formik.errors.email}
                label={t("form.email")}
                inputProps={{
                  pattern: "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$",
                }}
              />
              {errorMessageEmail.status !== "success" &&
                errorMessageEmail.message !== "" && (
                  <DisclaimerRed>
                    <ContentWithLinks>
                      {errorMessageEmail.message}
                    </ContentWithLinks>
                  </DisclaimerRed>
                )}
            </Grid>
          )}
          {fieldsConfig[FormFields.Country] && (
            <Grid item xs={12}>
              <CustomSelectLabel
                disabled={countryDropdownDisabled}
                htmlFor={FormFields.Country}
              >
                {t("form.country")}
              </CustomSelectLabel>
              {/* <TextField
                disabled={countryDropdownDisabled}
                fullWidth
                size="small"
                id={FormFields.Country}
                name={FormFields.Country}
                value={formik.values.country}
                onChange={formik.handleChange}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
                label={t("form.country")}
              /> */}
              <SelectCountryDropdown
                labelType="full"
                valueType="short"
                defaultOptionLabel="--"
                disabled={countryDropdownDisabled}
                value={formik.values.country}
                id={FormFields.Country}
                onChange={(event) =>
                  formik.setFieldValue(FormFields.Country, event)
                }
                name={FormFields.Country}
                formOliver={formOliver}
                formPersol={formPersol}
                formDavidClulow={formDavidClulow}
              />
              {invalidCountry && (
                <FormHelperText error>{formik.errors.country}</FormHelperText>
              )}
            </Grid>
          )}
          {fieldsConfig[FormFields.ConsentData] && (
            <>
              <Grid item xs="auto">
                <Select
                  required
                  inputProps={{
                    name: "consentData",
                    id: "consentData",
                  }}
                  value={formik.values.consent_data}
                  onChange={(event) => {
                    formik.handleChange(event);
                    event.target.options[0].disabled = true;
                    const picked = event.target.value === "true";
                    formik.setFieldValue(FormFields.ConsentData, picked);
                  }}
                  onBlur={formik.handleBlur}
                  options={[
                    {
                      label: t("form.marketing-option-yes").toUpperCase(),
                      value: "true",
                    },
                    {
                      label: t("form.marketing-option-no").toUpperCase(),
                      value: "false",
                    },
                  ]}
                />
                *
              </Grid>
              <Grid item xs={8}>
                <CustomCheckboxLabel>
                  <ContentWithLinks>{t("form.consentData")}</ContentWithLinks>
                </CustomCheckboxLabel>
                {!formik.values.consent_data &&
                  formik.values.consent_data !== "" && (
                    <DisclaimerRed>
                      <ContentWithLinks>
                        {t("form.consentDataFailure")}
                      </ContentWithLinks>
                    </DisclaimerRed>
                  )}
              </Grid>
            </>
          )}
          {fieldsConfig[FormFields.OnlyEmailMarketing] && (
            <>
              <Grid item xs={12}>
                <CustomCheckboxLabel>
                  <ContentWithLinks>
                    {t(`form.marketingConsent`)}
                  </ContentWithLinks>
                </CustomCheckboxLabel>
                <br />
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  size="small"
                  disabled={fieldsDisabled}
                  name={FormFields.OnlyEmailMarketing}
                  onChange={(event) => {
                    formik.handleChange(event);
                    formik.setValues({
                      ...formik.values,
                      email_marketing: event.target.checked,
                    });
                  }}
                  checked={formik.values.email_marketing}
                  checkedIcon={
                    formOakley ? (
                      <img src={iconCheck} width="13px" />
                    ) : undefined
                  }
                />
                <FormLabel>{t("form.emailMarketing")}</FormLabel>
              </Grid>
            </>
          )}
          {fieldsConfig[FormFields.PhoneMarketing] &&
            !fieldsConfig[FormFields.EmailMarketing] && (
              <>
                <Grid item xs="auto">
                  <Checkbox
                    size="small"
                    name={FormFields.PhoneMarketing}
                    onChange={formik.handleChange}
                    checked={formik.values.phone_marketing}
                    checkedIcon={
                      formOakley ? (
                        <img src={iconCheck} width="13px" />
                      ) : undefined
                    }
                  />
                </Grid>
                <Grid item xs>
                  <CustomCheckboxLabel>
                    <ContentWithLinks>
                      {storeIsdcData.isdc__language === "es"
                        ? t(`form.marketingConsent`)
                        : t(`${storeMapData.image_path}.smsOptIn`)}
                    </ContentWithLinks>
                  </CustomCheckboxLabel>
                </Grid>
              </>
            )}
          {fieldsConfig[FormFields.PhoneMarketing] &&
            fieldsConfig[FormFields.EmailMarketing] && (
              <StyledWrapper
                item
                xs
                direction={isContinentalCountry ? "row" : "column"}
              >
                {isContinentalCountry && (
                  <Grid item xs>
                    <Select
                      required
                      disabled={fieldsDisabled}
                      inputProps={{
                        name: "marketingAccepted",
                        id: "marketingAccepted",
                      }}
                      value={formik.values.marketingAccepted}
                      onChange={handleConsentSelectChange}
                      onBlur={formik.handleBlur}
                      options={[
                        {
                          label: t("form.marketing-option-yes").toUpperCase(),
                          value: "true",
                        },
                        {
                          label: t("form.marketing-option-no").toUpperCase(),
                          value: "false",
                        },
                      ]}
                    />
                    *
                  </Grid>
                )}
                <Grid item xs={9}>
                  <CustomCheckboxLabel>
                    <ContentWithLinks>
                      {t(`form.marketingConsent`)}
                    </ContentWithLinks>
                  </CustomCheckboxLabel>
                  {!isContinentalCountry && (
                    <>
                      <Select
                        required
                        m="16px 0 8px"
                        disabled={fieldsDisabled}
                        inputProps={{
                          name: "marketingAccepted",
                          id: "marketingAccepted",
                        }}
                        value={formik.values.marketingAccepted}
                        style={{
                          width: "97%",
                        }}
                        onChange={handleConsentSelectChange}
                        onBlur={formik.handleBlur}
                        options={[
                          {
                            label: t("form.marketing-option-yes").toUpperCase(),
                            value: "true",
                          },
                          {
                            label: t("form.marketing-option-no").toUpperCase(),
                            value: "false",
                          },
                        ]}
                      />
                      *
                    </>
                  )}
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!marketingEnabled}
                        checkedIcon={
                          formOakley ? (
                            <img src={iconCheck} width="13px" />
                          ) : undefined
                        }
                        size="small"
                        name={FormFields.EmailMarketing}
                        onChange={formik.handleChange}
                        checked={formik.values.email_marketing}
                      />
                    }
                    label={`${t("form.emailMarketing")}`}
                  />
                </Grid>
              </StyledWrapper>
            )}
          {
            // Consents section for Singapore forms
            formSEA &&
            (
              <StyledWrapper
                item
                xs
                direction={isContinentalCountry ? "row" : "column"}
              >
                {isContinentalCountry && (
                  <Grid item xs>
                    <Select
                      required
                      disabled={fieldsDisabled}
                      inputProps={{
                        name: "marketingAccepted",
                        id: "marketingAccepted",
                      }}
                      value={formik.values.marketingAccepted}
                      onChange={handleConsentSelectChange}
                      onBlur={formik.handleBlur}
                      options={[
                        {
                          label: t("form.marketing-option-yes").toUpperCase(),
                          value: "true",
                        },
                        {
                          label: t("form.marketing-option-no").toUpperCase(),
                          value: "false",
                        },
                      ]}
                    />
                    *
                  </Grid>
                )}
                <Grid item xs={9}>
                  <CustomCheckboxLabel>
                    <ContentWithLinks>
                      {t(`form.marketingConsent`)}
                    </ContentWithLinks>
                  </CustomCheckboxLabel>
                  {!isContinentalCountry && (
                    <>
                      <Select
                        required
                        m="16px 0 8px"
                        disabled={fieldsDisabled}
                        inputProps={{
                          name: "marketingAccepted",
                          id: "marketingAccepted",
                        }}
                        value={formik.values.marketingAccepted}
                        style={{
                          width: "97%",
                        }}
                        onChange={handleConsentSelectChange}
                        onBlur={formik.handleBlur}
                        options={[
                          {
                            label: t("form.marketing-option-yes").toUpperCase(),
                            value: "true",
                          },
                          {
                            label: t("form.marketing-option-no").toUpperCase(),
                            value: "false",
                          },
                        ]}
                      />
                      *
                    </>
                  )}
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!marketingEnabled}
                        required={formSEA}
                        checkedIcon={
                          formOakley ? (
                            <img src={iconCheck} width="13px" />
                          ) : undefined
                        }
                        size="small"
                        name={FormFields.EmailMarketing}
                        onChange={formik.handleChange}
                        checked={formik.values.email_marketing}
                      />
                    }
                    label={`${t("form.emailMarketing")}`}
                  />
                </Grid>
              </StyledWrapper>
            )}
          {loyalty && (
            <Grid container spacing={1} item xs={12} style={{width:"110%",border:"2px solid #e0e0e0", margin: "25px 0px 0px 15px", padding: "10px 15px 20px 10px"}}>
              
              <Grid item xs={12} style={{fontWeight: "bold", fontSize: "12px"}}>
                <ContentWithLinks>{t(`${storeMapData.image_path}.loyaltyProgramTitle`)}</ContentWithLinks>
              </Grid>
              
              <Grid item xs={12}>
                <CustomCheckboxLabel>
                    { !loyaltyEMEA && (
                    <ContentWithLinks>{t(`${storeMapData.image_path}.loyaltyProgram`)}</ContentWithLinks>
                    )}
                    { loyaltyEMEA && (
                    <ContentWithLinks>{t(`${storeMapData.image_path}.loyaltyProgramEMEA`)}</ContentWithLinks>
                    )}
                </CustomCheckboxLabel>
              </Grid>
              
              <Grid item xs={12} spacing={1}>
                <NativeSelect
                  required
                  style={{
                    margin: "5px 0px 0px 0px",
                    width: "97%",
                  }}
                  disabled={fieldsDisabled}
                  inputProps={{
                    name: "loyalty",
                    id: "loyalty",
                  }}
                  value={formik.values.loyalty}
                  onChange={(event) => {
                    formik.handleChange(event);
                    event.target.options[0].disabled = true;
                    const picked = event.target.value === "true";
                    formik.setFieldValue(FormFields.Loyalty, picked);
                  }}
                  onBlur={formik.handleBlur}
                >
                  <option value="">-</option>
                  <option value="true">
                    {t("form.marketing-option-yes").toUpperCase()}
                  </option>
                  <option value="false">
                    {t("form.marketing-option-no").toUpperCase()}
                  </option>
                </NativeSelect>{" "}
                *<br/>

                { formik.values.loyalty && formik.values.loyalty !== "" && (
                  <div>
                  {isInLoyaltyBlacklist(formik.values.email) && (
                    <DisclaimerRed>
                      <ContentWithLinks>
                        {t("form.warning_loyalty")}
                      </ContentWithLinks>
                    </DisclaimerRed>
                  )}
                  { !isInLoyaltyBlacklist(formik.values.email) && loyaltyEMEA && (
                    <FormControlLabel
                    control={
                      <Checkbox
                        disabled={fieldsDisabled}
                        size="small"
                        name={FormFields.LoyaltyAccepted}
                        onChange={formik.handleChange}
                        checked={formik.values.loyalty_accepted}
                      />
                    }
                    label={
                      <CustomCheckboxLabel>
                        <ContentWithLinks>{t(`form.confirm_loyalty.${confirmLoyaltyCountry}`)}</ContentWithLinks>
                      </CustomCheckboxLabel>
                    }
                    />
                  )}
                </div>
                )}
              </Grid>
            </Grid>
          )}
          {!isSignatureUnavailable && (
            <Grid item xs={12}>
              <FormLabel required>{t("form.signature")}</FormLabel>
              <ReactSignatureCanvas
                dotSize={0.1}
                ref={sigCanvas}
                canvasProps={{
                  width: 420,
                  height: 80,
                  className: "signatureCanvas",
                  style: {
                    maxWidth: "100%",
                    border: signatureError
                      ? "2px solid #d32f2f"
                      : "1px solid #dadada",
                    borderRadius: 5,
                  },
                }}
                onEnd={() => {
                  setSignatureError(sigCanvas.current.isEmpty());
                }}
              />
              {signatureError && (
                <FormHelperText error>
                  {t("form.signature-required")}
                </FormHelperText>
              )}
            </Grid>
          )}
          {publicForm && (
            <GridRecaptcha item xs={12}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.RECAPTCHA_API_KEY}
                onChange={() => setDisabledSubmit(!disabledSubmit)}
              />
            </GridRecaptcha>
          )}
          {fieldsConfig[FormFields.DisclaimerUS] && (
            <Grid item xs={12}>
              <Disclaimer variant="body2">
                <ContentWithLinks>{t("form.disclaimer.WIFIUS")}</ContentWithLinks>
              </Disclaimer>
            </Grid>
          )}
          {fieldsConfig[FormFields.DisclaimerROW] && (
            <Grid item xs={12}>
              <Disclaimer variant="body2">
                <ContentWithLinks>
                  {t("form.disclaimer.default")}
                </ContentWithLinks>
              </Disclaimer>
            </Grid>
          )}
          <Grid item xs={12} container justifyContent="center">
            <SubmitButton
              disabled={
                inProgress ||
                (publicForm && disabledSubmit) ||
                errorMessageEmail.status === "error" ||
                invalidAddressFields ||
                invalidPhoneNumber ||
                ( formik.values.loyalty===true && isInLoyaltyBlacklist(formik.values.email)) // loyalty opt-in is consistent with email domain
              }
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
            >
              {inProgress ? (
                <CircularProgress size="30px" color="inherit" />
              ) : (
                <span>{t("form.submit")}</span>
              )}
            </SubmitButton>
          </Grid>
        </Grid>
      </form>
      <AlertDialog dialogContent={alertDialogContent} isOpen={alertDialogOpen}>
        <>
          {!usingPos && submitSuccess && (
            <Button onClick={handleCloseAndClearForm}>{t("form.ok")}</Button>
          )}
          {!submitSuccess && (
            <Button onClick={retrySubmit}>{t("form.retry")}</Button>
          )}
          {!usingPos && !submitSuccess && (
            <Button onClick={handleCloseAndClearForm}>
              {t("form.new_form")}
            </Button>
          )}
          {usingPos && !submitSuccess && (
            <Button onClick={submitPOSFailed}>{t("form.opt_in_email")}</Button>
          )}
        </>
      </AlertDialog>
    </div>
  );
};

export default WiFiForm;
